
$moduleNameSpace: dynamic-name-space;





$focus-trap--index: 1002;

$focus-trap--color: #524940;

$focus-trap--bg-color: #fff;

[data-sparta-container] .focus-trap-utility {
  [class^="focus-boundary"] {
    position: absolute;
    z-index: $focus-trap--index;
    top: 0;
    left: 0;
    background-color: $focus-trap--bg-color;
    padding: 3px;
    color: $focus-trap--color;
    outline: 1px dotted;
    margin: 5px;
    &:not(:focus) {
      opacity: 0;
      pointer-events: none;
      height: 0;
      width: 0;
      overflow: hidden;
    }
    &.focus-boundary-bounce,
    &.focus-boundary-bottom {
      bottom: 0;
      top: auto;
    }
  }
}
